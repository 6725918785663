export {default as Edu} from './Edu';
export {default as Events} from './Events';
export {default as EdLangu} from './Lang';
export {default as Press} from './Press';
export {default as Lang} from './Lang';
export {default as Commu} from './Commu';
export {default as Pro} from './Pro';
export {default as ResumeHeader} from './ResumeHeader';
export {default as Skills} from './Skills';
export {default as Techni} from './Techni';
export {default as Content} from './Content';
export {default as Label} from './Label';
export {default as List} from './List';
export {default as Section} from './Section';